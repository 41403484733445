// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campaign-js": () => import("./../../../src/pages/campaign.js" /* webpackChunkName: "component---src-pages-campaign-js" */),
  "component---src-pages-checklist-js": () => import("./../../../src/pages/checklist.js" /* webpackChunkName: "component---src-pages-checklist-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-result-js": () => import("./../../../src/pages/contacts-result.js" /* webpackChunkName: "component---src-pages-contacts-result-js" */),
  "component---src-pages-contest-result-js": () => import("./../../../src/pages/contest_result.js" /* webpackChunkName: "component---src-pages-contest-result-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-microcms-news-news-id-js": () => import("./../../../src/pages/news/{microcmsNews.newsId}.js" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-js" */)
}

